import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { useSitesStore } from "@/pinia/sites.store";
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    api_token: string;
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    user = {} as User;
    loginResponse = null;
    isAuthenticated = !!JwtService.getToken();

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Get current client object
     * @returns Object
     */
    get getLoginResponse() {
        return this.loginResponse;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_LOGIN_RESPONSE](response) {
        this.loginResponse = response;
    }

    @Mutation
    [Mutations.SET_AUTH](payload) {
        this.isAuthenticated = true;
        this.errors = {};
        this.loginResponse = null;
        JwtService.saveToken(payload.token);
        JwtService.saveExpireAt(payload.expireAt);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = {};
        JwtService.destroyToken();
    }

    @Action
    [Actions.LOGIN](credentials) {
        return ApiService.post('Account/login', credentials)
            .then(({ data }) => {
                if (data.code === 603) {
                    this.context.commit(Mutations.SET_LOGIN_RESPONSE, data);
                } else {
                    this.context.commit(Mutations.SET_AUTH, data);
                }
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, { login: response.data });
            });
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.REGISTER](credentials) {
        return ApiService.post('register', credentials)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_AUTH, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return ApiService.post('forgot_password', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.VERIFY_AUTH]() {
        if (JwtService.getToken() && !JwtService.isTokenExpired()) {
            ApiService.setHeader();
            ApiService.get('Account/CurrentUser')
                .then((response) => {
                    this.context.commit(Mutations.SET_USER, {...response.data})
                    useSitesStore().init();
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, response.data);
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }
}
