import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { Mutations } from '@/store/enums/StoreEnums';

@Module
export default class TooltipModule extends VuexModule {
    tooltip = '';

    /**
     * Get current page title
     * @returns string
     */
    get getTooltip() {
        return this.tooltip;
    }

    @Mutation
    [Mutations.SET_TOOLTIP_TEXT](text) {
        this.tooltip = text;
    }
}
