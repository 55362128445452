import ApiService from '@/core/services/ApiService';
import { defineStore } from 'pinia';
import lodash from "lodash";
import { toRaw } from 'vue';

export const useSitesStore = defineStore('sites.store', {
    state: () => ({
        rawReady: false,
        rawSites: [],
        rawCurrentSite: null,
    }),
    getters: {
        ready: (state) => state.rawReady,
        sites: (state) => toRaw(state.rawSites),
        currentSite: (state) => toRaw(state.rawCurrentSite),
    },
    actions: {
        init() {
            this.rawReady = false;
            this.rawSites = [];

            ApiService.get("/Site/all")
            .then((response) => {
                if (
                    response.data &&
                    response.data.length > 0
                ) {
                    this.rawSites = response.data.sort(function (a, b) {
                            return a.siteName.localeCompare(b.siteName, undefined, {
                            numeric: true,
                            sensitivity: "base",
                        });
                    });
                }
                this.rawReady = true;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        setCurrentSite(siteGuid) {
            const site = this.rawSites.find(c => c.guid === siteGuid);
            this.rawCurrentSite = site ?? null;
        },
        save(site, callback) {
            ApiService.post("/Site", site)
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        if (!site.guid) {
                            const sites = lodash.clone(this.rawSites);
                            sites.push(lodash.clone(response.data));
                            this.rawSites = lodash.clone(sites);
                        } else {
                            const index = this.rawSites.findIndex((a) => a.guid === site.guid);
                            if (index !== -1) {
                                const sites = lodash.clone(this.rawSites);
                                sites[index] = lodash.clone(response.data);
                                this.rawSites = lodash.clone(sites);
                            }
                        }
                        callback(true);
                    } else {
                        callback(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    callback(false);
                });
        },
        delete(siteGuid, callback) {
            ApiService.delete(`/Site?guid=${siteGuid}`)
                .then((response) => {
                    if (response && response.status === 200) {
                        const index = this.rawSites.findIndex(
                            (site) => site.guid === siteGuid
                        );
                        if (index !== -1) {
                            const sites = lodash.clone(this.rawSites);
                            sites.splice(index, 1);
                            this.rawSites = lodash.clone(sites);
                        }
                        callback(true);
                    } else {
                        callback(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    callback(false);
                });
        }
    },
});
