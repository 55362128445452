import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import ThemeModeModule from '@/store/modules/ThemeModeModule';
import TooltipModule from '@/store/modules/TooltipModule';

config.rawError = true;

const store = createStore({
    modules: {
        AuthModule,
        BodyModule,
        BreadcrumbsModule,
        ConfigModule,
        ThemeModeModule,
        TooltipModule,
    },
});

export default store;
