import { createRouter, createWebHashHistory } from 'vue-router';
import { Mutations, Actions } from '@/store/enums/StoreEnums';
import store from '@/store';

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
        component: () => import('@/layout/Layout.vue'),
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/views/Dashboard.vue'),
            },
            {
                path: '/sites',
                name: 'sites',
                component: () => import('@/views/Sites/Sites.vue'),
            },
            {
                path: '/stations',
                name: 'stations',
                component: () => import('@/views/Stations/Stations.vue'),
            },
            {
                path: '/station/edit/:guid',
                name: 'editStation',
                component: () => import('@/views/Stations/Station.vue'),
            },
            {
                path: '/station/new',
                name: 'newStation',
                component: () => import('@/views/Stations/Station.vue'),
            },
            {
                path: '/sensors',
                name: 'sensors',
                component: () => import('@/views/Sensors/Sensors.vue'),
            },
            {
                path: '/sensor/edit/:guid',
                name: 'editSensor',
                component: () => import('@/views/Sensors/Sensor.vue'),
            },
            {
                path: '/sensor/new',
                name: 'newSensor',
                component: () => import('@/views/Sensors/Sensor.vue'),
            },
            {
                path: '/baselines',
                name: 'baselines',
                component: () => import('@/views/Baselines/Baselines.vue'),
            },
            {
                path: '/baseline/edit/:guid',
                name: 'editBaseline',
                component: () => import('@/views/Baselines/Baseline.vue'),
            },
            {
                path: '/baseline/new',
                name: 'newBaseline',
                component: () => import('@/views/Baselines/Baseline.vue'),
            },
            {
                path: '/inventory',
                name: 'inventory',
                component: () => import('@/views/Inventory/Inventory.vue'),
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('@/views/Users/Users.vue'),
            },
        ],
    },
    {
      path: "/",
      component: () => import("@/views/Auth/Auth.vue"),
      children: [
        {
            path: "/login",
            name: "login",
            component: () => import("@/views/Auth/Login.vue"),
        },
        {
            path: "/forgot",
            name: "forgot",
            component: () => import("@/views/Auth/Forgot.vue"),
        },
      ],
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/Error404.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, _, next) => {
    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);

    store.dispatch(Actions.VERIFY_AUTH);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);

    if (store.getters.isUserAuthenticated) {
        next();
    } else if (to.name !== 'login' && to.name !== 'forgot') {
        next({ name: "login"});
    } else {
        next();
    }
});

export default router;
