import ApiService from '@/core/services/ApiService';
import { defineStore } from 'pinia';
import { toRaw } from 'vue';

export const useEnumsStore = defineStore('enums.store', {
    state: () => ({
        rawReady: false,
        rawEnums: {
            stock: null,
            stationType: null,
            projection: null,
            gnssType: null,
            gsmType: null,
        },
    }),
    getters: {
        ready: (state) => state.rawReady,
        enums: (state) => toRaw(state.rawEnums),
    },
    actions: {
        init() {
            if (this.rawReady) {
                return false;
            }

            this.rawReady = false;

            ApiService.get("/Enum/stock")
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data
                    ) {
                        this.rawEnums.stock = response.data;
                    }
                    
                    this.checkEnums();
                })
                .catch((error) => {
                    console.log("stock", error);
                });

            ApiService.get("/Enum/stationType")
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data
                    ) {
                        this.rawEnums.stationType = response.data;
                    }
                    
                    this.checkEnums();
                })
                .catch((error) => {
                    console.log("stationType", error);
                });

            ApiService.get("/Enum/projection")
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data
                    ) {
                        this.rawEnums.projection = response.data;
                    }
                    
                    this.checkEnums();
                })
                .catch((error) => {
                    console.log("projection", error);
                });

            ApiService.get("/Enum/gnssType")
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data
                    ) {
                        this.rawEnums.gnssType = response.data;
                    }
                    
                    this.checkEnums();
                })
                .catch((error) => {
                    console.log("gnssType", error);
                });

            ApiService.get("/Enum/gsmType")
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data
                    ) {
                        this.rawEnums.gsmType = response.data;
                    }
                    
                    this.checkEnums();
                })
                .catch((error) => {
                    console.log("gsmType", error);
                });
        },
        checkEnums() {
            if (this.rawEnums.stock !== null && this.rawEnums.stationType !== null && this.rawEnums.projection !== null && this.rawEnums.gnssType !== null && this.rawEnums.gsmType !== null) {
                this.rawReady = true;
                console.log("ENUMS", this.enums);
            }
        },
        getEnum(type) {
            if (this.rawEnums[type] && this.rawEnums[type].length > 0) {
                let rawEnum = {};
                this.rawEnums[type].forEach(entity => {
                    rawEnum[entity.name] = entity.value;
                });

                return rawEnum;
            }

            return {};
        },
        getEnumLabel(type, value) {
            if (this.rawEnums[type] && this.rawEnums[type].length > 0) {
                let name = "";
                this.rawEnums[type].forEach(entity => {
                    if (entity.value === value) {
                        name = entity.name;
                    }
                });

                return name;
            }

            return "";
        },
    },
});
