import dayjs from "dayjs";

const ID_TOKEN_KEY = 'accessToken' as string;
const ID_EXPIRE_TOKEN_KEY = 'accessTokenExpireAt' as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description get token expire at form localStorage
 */
export const getExpireAt = (): string | null => {
    return window.localStorage.getItem(ID_EXPIRE_TOKEN_KEY);
};

/**
 * @description get token form localStorage
 */
export const isTokenExpired = (): boolean => {
    const expireAt = getExpireAt();
    if (!expireAt) {
        return true;
    }

    const local = dayjs(expireAt);
    const diff = local.diff(dayjs(), 'minutes');
  
    return diff <= 0;
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description save token expire at into localStorage
 * @param expireAt: string
 */
export const saveExpireAt = (expireAt: string): void => {
    window.localStorage.setItem(ID_EXPIRE_TOKEN_KEY, expireAt);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
    window.localStorage.removeItem(ID_EXPIRE_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, saveExpireAt, isTokenExpired };
