import LayoutConfigTypes from '@/core/config/LayoutConfigTypes';

const config: LayoutConfigTypes = {
    themeName: 'Metronic',
    themeVersion: '8.1.1',
    demo: 'demo8',
    main: {
        type: 'default',
        primaryColor: '#009EF7',
        logo: {
            dark: 'media/logos/default-dark.svg',
            light: 'media/logos/default.svg',
        },
    },
    illustrations: {
        set: 'sketchy-1',
    },
    loader: {
        logo: 'media/logos/default-dark.svg',
        display: true,
        type: 'default',
    },
    scrollTop: {
        display: true,
    },
    header: {
        display: true,
        menuIcon: 'font',
        width: 'fluid',
        fixed: {
            desktop: true,
            tabletAndMobile: true,
        },
    },
    toolbar: {
        display: true,
        width: 'fluid',
        fixed: {
            desktop: true,
            tabletAndMobile: true,
        },
    },
    aside: {
        display: true,
        theme: 'light',
        fixed: true,
        menuIcon: 'font',
        minimized: false,
        minimize: true,
        hoverable: true,
    },
    content: {
        width: 'fluid',
    },
    footer: {
        width: 'fluid',
    },
};

export default config;
