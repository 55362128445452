import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router/index.js';
import store from './store';
import ElementPlus from 'element-plus';
import i18n from '@/components/i18n';
import { setDefaultOptions } from 'esri-loader';
setDefaultOptions({ version: '4.23' })
import { loadCss } from 'esri-loader';

// Esri CSS
loadCss();

// Synfusion license
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFac1dJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RhXn9WcXJRR2NbWEQ=');

//imports for app initialization
import ApiService from '@/core/services/ApiService';
import { initApexCharts } from '@/core/plugins/apexcharts';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';

import '@/core/plugins/prismjs';
import 'bootstrap';

const app = createApp(App);

app.use(createPinia());
app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount('#app');
